













import { Component, Vue } from 'vue-property-decorator';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';

@Component({
  components: { GeneralCrudList },
})
export default class CompanyStructureUnit extends Vue {
  model: any = [
    { name: 'name', label: 'Nazwa', type: 'text' },
    { name: 'code', label: 'Kody', type: 'text' },
  ];
  listOptions: any = [
    { field: 'name', label: 'Nazwa' },
    { field: 'code', label: 'Kody' },
  ];
}
